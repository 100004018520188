import React from 'react';
import '../style/style.css';
import { t } from 'i18next';
import Carousel from 'react-bootstrap/Carousel';
import imageSrc from '../img/bg_video.mp4';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import imgCES from '../img/2024_CES.png';

/**
 * xs, extra-small: 0px
    sm, small: 600px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1920px
 */
    

export default function MainTest() {

    const media760 = useMediaQuery('(max-width:760px)');
    sessionStorage.setItem('imgOn', false);

    const useStyles = makeStyles((theme) => ({

        img_ces: {
            width : "90px",
            height : "130px",
            marginTop : "75px",
            marginLeft : "25px",
            zIndex: 99,
            position: "fixed",
            
            [theme.breakpoints.down('md')]: {
                width : "60px",
                height : "85px",
                marginTop : "75px",
                marginLeft : "25px",
            },
            [theme.breakpoints.down('sm')]: {
                width : "45px",
                height : "65px",
                marginTop : "70px",
                marginLeft : "15px",
                
            },
        },
   }));
   const classes = useStyles();

    return (
        <div>
            <div>
                <img src={imgCES} className={classes.img_ces} alt='2024 CES 혁신상 이미지'/>
            </div>
            <div className="main-bg-wrap">
                <video className="bg-video__content" autoPlay muted loop playsInline>
                <source src={imageSrc} type='video/mp4'/>
                Your browser is not supported!
                </video>

                <Carousel>
                    <Carousel.Item>
                        {media760 === false ?
                        <div className="content justify-content-center">
                            <span>{t('main_top1-1')}</span>
                            <span>{t('main_top1-2')}</span>
                            <span>{t('main_top1-3')}</span>
                            <span className='mt-10'>{t('main_top1-4')}</span>
                            <span>{t('main_top1-5')}</span>
                        </div>  
                        :
                        <div className="content justify-content-center">
                            <span>{t('main_top1-1')} {t('main_top1-2')} {t('main_top1-3')}</span>
                            <span className='mt-10'>{t('main_top1-4')} {t('main_top1-5')}</span>
                        </div>  
                        }
                    </Carousel.Item>
                    <Carousel.Item>
                        {media760 === false ?
                        <div className="content justify-content-center">
                            <span>{t('main_top2-1')}</span>
                            <span>{t('main_top2-2')}</span>
                            <span>{t('main_top2-3')}</span>
                            <span className='my-10'>{t('main_top2-4')}</span>
                            <span>{t('main_top2-5')}</span>
                            <span>{t('main_top2-6')}{t('main_top2-7')}</span>
                        </div>
                        :
                        <div className="content justify-content-center">
                            <span>{t('main_top2-1')} {t('main_top2-2')} {t('main_top2-3')}</span>
                            <span className='my-10'>{t('main_top2-4')} {t('main_top2-5')} {t('main_top2-6')} {t('main_top2-7')}</span>
                        </div>
                        }
                    </Carousel.Item>
                    <Carousel.Item>
                        {media760 === false ?
                        <div className="content justify-content-center">
                            <span>{t('main_top3-1')}</span>
                            <span>{t('main_top3-2')}</span>
                            <span>{t('main_top3-3')}</span>
                            <span className='mt-10'>{t('main_top3-4')}</span>
                            <span>{t('main_top3-5')}</span>
                        </div>
                        :
                        <div className="content justify-content-center">
                            <span>{t('main_top3-1')} {t('main_top3-2')} {t('main_top3-3')}</span>
                            <span className='mt-10'>{t('main_top3-4')} {t('main_top3-5')}</span>
                        </div>
                        }
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
    )
}